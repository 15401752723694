import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()

export class RequestInterceptor implements HttpInterceptor {

    constructor(
        private router: Router
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url && request.url.includes(environment.paymongoBaseUrl)) {
            let token = btoa(environment.paymongoKey);
            request = request.clone({
                setHeaders: {
                    'Authorization': `Basic ${token}`
                }
            })
        } else {
            let adminToken = JSON.parse(localStorage.getItem('myEatsAdminToken'));
            if (adminToken && adminToken.role === 'restaurant.admin') {
                request = request.clone({
                    setHeaders: {
                        'Authorization': `Bearer ${adminToken.token}`
                    }
                })
            }
        }

        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpResponse) {
                    //console.log('event--->>>', event);
                }
            }, error => {
                if (error instanceof HttpResponse) {
                    //api call error
                    console.log('error in calling API : ', error);
                }
                if (error.status) {
                    if ((error.status === 401) || (error.status === 403) || (error.status === 404)) {
                        localStorage.removeItem('myEatsAdminToken');
                        this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                    } else if (error.status === 477) {
                        if (error.error) {
                            if (error.error.status) {
                                if (error.error.status === 'failed') {
                                    this.router.navigate(['/verify-phone']);
                                }
                            }
                        }
                    }
                }
            }
            )
        )
    }


}
export const environment = {
  production: false,
  currency: '₱',
  baseUrl: 'https://myeats.cedexdemo.in/api/v1',
  paymongoKey: 'pk_test_aV5qrjGe2xD7H8iFRWMmFG6N',
  paymongoBaseUrl: 'https://api.paymongo.com/v1',
  googleMapKey: 'AIzaSyA6gEfQ--K1SIz2Zj8CRzWHUiK68bSEDXY'
};



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/guards/auth.guard';
import { RedirectGuard } from './common/guards/redirect.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    canActivate: [RedirectGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate: [RedirectGuard]
  },
  {
    path: 'otp/:phone',
    loadChildren: () => import('./verify-reset-password/verify-reset-password.module').then(m => m.VerifyResetPasswordModule),
    canActivate: [RedirectGuard]
  },
  {
    path: 'reset-password/:phone/:token',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    canActivate: [RedirectGuard]
  },
  {
    path: 'verify-phone',
    loadChildren: () => import('./veirfy-phone/verify-phone.module').then(m => m.VerfyPhoneModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./common/layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
